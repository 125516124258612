import {registerLocaleData} from '@angular/common';
import pt from '@angular/common/locales/pt';
import {NgModule} from '@angular/core';
import {BrowserModule,} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {NgxMaskModule} from 'ngx-mask';
import {
  NzDropDownModule,
  NzIconModule,
  NzMenuModule,
  NzModalService,
  NzPopoverModule,
  NzTableModule
} from 'ng-zorro-antd';
import {RedirectGuard} from './core/guards/redirect-guard.service';

registerLocaleData(pt, 'pt-BR');

@NgModule({
    declarations: [
        AppComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    NgxMaskModule.forRoot(),
    NzDropDownModule,
    NzIconModule,
    NzMenuModule,
    NzPopoverModule,
    NzTableModule
  ],
    providers: [
        NzModalService,
        RedirectGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
