import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CrudService} from 'src/app/core/services/abstract/crud.service';
import {Protocolo} from 'src/app/shared/models/protocolo-model';
import {Juntada} from 'src/app/shared/models/juntada-model';
import {JuntadaResponse} from 'src/app/shared/models/juntada.response.model';
import {Notificacao} from "../../shared/models/notificacao-model";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Injectable({
  providedIn: "root"
})
export class NotificacaoService extends CrudService<Notificacao> {

  private fonteQuantidadeNotificacoesNaoLidas = new BehaviorSubject<number>(0);
  quantidadeNotificacoesAtuais = this.fonteQuantidadeNotificacoesNaoLidas.asObservable();

  private fonteRecarregarQuantidadeNotificacoesNaoLidas = new BehaviorSubject<boolean>(false);
  recarregarQuantidadeNotificacoesAtuais = this.fonteRecarregarQuantidadeNotificacoesNaoLidas.asObservable();

  constructor(
    protected http: HttpClient,
    private sanitizer: DomSanitizer) {
    super(http, "notificacao");
  }

  recuperarQuantidadeNotificacoesNaoLidas(cpfCnpj: string): Observable<number> {
    return this.http.get<number>(`${this.apiUrl}/recuperar-quantidade-nao-lida/${cpfCnpj}`);
  }

  registrarCienciaNotificacao(id: number): any {
    return this.http.patch<any>(`${this.apiUrl}/${id}/registrar-ciencia-notificacao`, null);
  }

  recuperarTodasNotificacoesByNumeroProtocoloAndCpfCnpj(numeroProtocolo: string, cpfCnpj: string): Observable<Notificacao[]> {
    return this.http.get<Notificacao[]>(`${this.apiUrl}/recuperar-todas-notificacoes/${numeroProtocolo}/${cpfCnpj}`);
  }

  atualizarQuantidadeNotificacoesNaoLidas(novaQuantidadeNotificacoesNaoLidas: number) {
    this.fonteQuantidadeNotificacoesNaoLidas.next(novaQuantidadeNotificacoesNaoLidas);
  }

  recarregarQuantidadeNotificacoesNaoLidas(recarregarQuantidadeNotificacoesNaoLidas: boolean) {
    this.fonteRecarregarQuantidadeNotificacoesNaoLidas.next(recarregarQuantidadeNotificacoesNaoLidas);
  }

  sanitizeHtmlContent(content: string): SafeHtml {
    content = content.replace(/<title[^>]*>.*?<\/title>/gi, '');
    const regex = /<\/?(!DOCTYPE|html|head|body|meta)[^>]*>/gi;
    const sanitizedContent = content.replace(regex, '');

    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
  }
}
