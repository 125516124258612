import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MENU_CONFIG} from '../../../menu/menu.config';
import {ThemeConstantService} from '../../theme-constant.service';
import {takeWhile} from 'rxjs/operators';
import {NotificacaoService} from "../../../services/notificacao.service";
import {UsuarioAutenticado} from "../../../../shared/models/usuario-autenticado.model";
import {UsuarioAutenticadoService} from "../../../services/usuario-autenticado.service";

@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html'
})

export class SideNavComponent implements OnInit, OnDestroy {

  usuarioAutenticado: UsuarioAutenticado;

  private componentActive = true;

  public menuItems: any[]
  isFolded: boolean;
  isSideNavDark: boolean;

  quantidadeNotificacoesNaoLidas: number;

  @Input() recarregarQuantidadeNotificacoesNaoLidas: boolean;
  @Output() qntNotificacoesEvent: EventEmitter<number> = new EventEmitter();

  constructor(
    private themeService: ThemeConstantService,
    private notificacaoService: NotificacaoService,
    private usuarioService: UsuarioAutenticadoService) {
  }

  ngOnInit() {
    this.usuarioService.getUsuarioAutenticado()
      .subscribe(usuarioAutenticado => {
        this.usuarioAutenticado = usuarioAutenticado;

        this.recuperarQntNotificacoesNaoLidas(this.usuarioAutenticado.cpfCnpj);
      });

    this.notificacaoService.recarregarQuantidadeNotificacoesAtuais.subscribe(recarregar => {
      if(recarregar){
        this.recuperarQntNotificacoesNaoLidas(this.usuarioAutenticado.cpfCnpj);
      }
    })

    this.menuItems = MENU_CONFIG.filter(menuItem => menuItem);

    this.themeService.isMenuFoldedChanges
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(isFolded => this.isFolded = isFolded);

    this.themeService.isSideNavDarkChanges
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(isDark => this.isSideNavDark = isDark);
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  recuperarQntNotificacoesNaoLidas(cpfCnpj) {
    this.notificacaoService
      .recuperarQuantidadeNotificacoesNaoLidas(cpfCnpj)
      .subscribe(qntNotificacoesNaoLidas => {
        this.quantidadeNotificacoesNaoLidas = qntNotificacoesNaoLidas;
        this.qntNotificacoesEvent.next(this.quantidadeNotificacoesNaoLidas);
      });
  }

}
