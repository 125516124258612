import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UploadFile, NzMessageService, NzModalRef, NzModalService } from 'ng-zorro-antd';
import { tap, catchError, takeWhile } from 'rxjs/operators';
import { AdvogadoService } from 'src/app/core/services/advogado.service';
import { ParteService } from 'src/app/core/services/parte.service';
import { throwError } from 'rxjs';
import { CPFService } from 'src/app/core/services/cpf.service';
import { HttpErrorResponse } from '@angular/common/http';
import { OrganizacaoService } from 'src/app/core/services/organizacao.service';
import { SEGURANCA_REDIRECT_URL_OLD, SEGURANCA_URL_OLD } from 'src/app/shared/constantes';
import { environment } from 'src/environments/environment';
import { differenceInCalendarDays } from 'date-fns';
import { CodigoVerificadorService } from 'src/app/core/services/codigo-verificador.service';
import {TERMO_COMPROMISSO} from 'src/app/module/publico/parte/cadastro/termo'

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})
export class CadastroComponent implements OnInit {

  parteForm: FormGroup;
  confirmModal?: NzModalRef;

  acceptedFiles = "application/pdf,audio/mp3,video/mp4";
  filesLimit = 100;
  fileSizeLimit = 10000;
  files: File[] = [];
  termoCompromisso: String;
  aceiteTermoCompromisso = false;
  loading = false;
  advogadoLoading = false;
  advogadoFieldsVisibled = false;
  isModalVisible = false;
  isModalLoading = false;
  organizacoesLoading = true;
  emailConfirmacaoLoading = true;
  codigoVerificacaoLoading = true;
  today = new Date();
  confirmacaoValidada = false;
  nomeCompletoAdvo: String;
  emailAdvo: String;
  rgAdvo: String;
  dataNascimentoAdvo: Date;
  sexoAdvo: String;
  loadingVerificacaoCPF = false;

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;

  organizacoes$ = this.organizacoesService.listarPorTipoConselhoSeccional()
    .pipe(
      tap(() => {
        this.organizacoesLoading = false;
      })
    );

  emailsParaConfirmacao: String[];

  constructor(
    private advogadoService: AdvogadoService,
    private parteService: ParteService,
    private msg: NzMessageService,
    private modalService: NzModalService,
    private organizacoesService: OrganizacaoService
  ) { }

  ngOnInit() {
    this.parteForm = new FormGroup({
      advogado: new FormControl('', Validators.required),
      cpf: new FormControl('', [Validators.required, CPFService.isValidCpf()]),
      nome: new FormControl('', Validators.required),
      rg: new FormControl(''),
      dataNascimento: new FormControl(''),
      sexo: new FormControl(''),
      telefoneCelular: new FormControl('', Validators.required),
      telefoneFixo: new FormControl(''),
      email: new FormControl('', [Validators.required]),
      emailConfirmacao: new FormControl('', Validators.required),
      arquivoAutoRetrato: new FormControl(''),
      arquivoAutoRetratoPath: new FormControl(''),
      arquivoCPF: new FormControl(''),
      arquivoCPFPath: new FormControl(''),
      aceiteTermoCompromisso: new FormControl(false, Validators.required),
      codigoOrganizacao: new FormControl(null, Validators.required),
      emailAnonimatizado: new FormControl(''),
      codigoEncripto: new FormControl(''),
      codigoVerificacao: new FormControl('', [CodigoVerificadorService.isValid()]),
      validado: new FormControl(false, )
    });

    this.termoCompromisso = TERMO_COMPROMISSO;
  }


  verificarSeAdvogado(cpf){
    if (this.parteForm.controls.cpf.invalid || this.loadingVerificacaoCPF) {
      return
    }
    this.advogadoService.recuperar(this.parteForm.controls.cpf.value)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.parteForm.controls.advogado.setValue(0)
          this.atualizarParteExistente(cpf)

          return throwError(error);
         })
      ).subscribe(() =>{
        this.parteForm.controls.advogado.setValue(1)
        this.atualizarParteExistente(cpf)
      }
    )
  }

  atualizarParteExistente(cpf){
    if (this.parteForm.controls.cpf.invalid || this.loadingVerificacaoCPF) {
      return
    }
    this.loadingVerificacaoCPF = true;
    this.parteService.atualizarParteCPFExistente(cpf,this.parteForm.controls.advogado.value == 1)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.loadingVerificacaoCPF = false;
        this.recuperarAdvogado(cpf);
        return throwError(error);
      })
    ).subscribe(isMembro => {
      this.showConfirm("Peticionamento eletrônico", "Localizado usuário com o CPF informado! Os dados de acesso serão encaminhados ao e-mail cadastrado.", () => this.redirecionarAutenticacaoSegurancaReturnUrlToPeticionamento())
    })

  }

  recuperarAdvogado(value) {
    this.loading = true;

    if (this.parteForm.controls.advogado.value == 1 && this.parteForm.controls.cpf.valid) {
      this.resetParteInferiorForm();
      this.parteForm.controls.nome.disable();
      this.advogadoService.recuperar(this.parteForm.controls.cpf.value)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.parteForm.controls.advogado.setValue(0);
            this.msg.warning('Advogado não localizado.');
            this.resetParteInferiorForm();
            this.loading = false;
            return throwError(error);
          })
        )
        .subscribe(advogado => {
          if(advogado.emails.length == 0){
            this.msg.error('Seu cadastro não possui e-mail, favor entrar em contato com a sua seccional para atualização cadastral.');
          }
          this.emailsParaConfirmacao = null;
          this.parteForm.controls.validado.setValue(false);
          this.parteForm.controls.emailAnonimatizado.setValue('');
          this.parteForm.controls.emailConfirmacao.enable();
          this.parteForm.controls.codigoVerificacao.setValue('');

          this.nomeCompletoAdvo = advogado.nome;
          this.parteForm.controls.nome.setValue(advogado.nome);
          this.emailsParaConfirmacao = advogado.emails;
          this.emailConfirmacaoLoading = false;
          this.loading = false;
        });
    } else if (this.parteForm.controls.advogado.value == 0) {
      this.resetParteInferiorForm();
      this.parteForm.controls.nome.enable();
      this.parteForm.controls.emailConfirmacao.enable();
      this.loading = false;
    }
  }

  iniciarValidacaoEmailAdvogado(value, cpf) {
    this.loading = true;
    this.emailConfirmacaoLoading = true;

    if (this.parteForm.controls.advogado.value == 1 && this.parteForm.controls.cpf.valid && this.parteForm.controls.emailConfirmacao.value) {

      this.advogadoService.iniciarValidacaoDuasEtapas(this.parteForm.controls.emailConfirmacao.value, this.parteForm.controls.cpf.value)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.msg.warning('Falha ao enviar código para usuário.');
            this.loading = false;
            return throwError(error);
          })
        )
        .subscribe(validacaoDuasEtapasAdvogado => {
          this.parteForm.patchValue({
            emailAnonimatizado: validacaoDuasEtapasAdvogado.emailAnonimatizado,
            codigoEncripto: validacaoDuasEtapasAdvogado.codigoEncripto
          });
          this.parteForm.controls.emailConfirmacao.disable();
          this.emailConfirmacaoLoading = false;
        });
    }

    this.loading = false;
  }

  validarCodigoVerificacao(value) {
    this.loading = true;
    this.codigoVerificacaoLoading = true;

    if (this.parteForm.controls.codigoVerificacao.valid) {
      this.advogadoService.validarCodigoInformado(this.mapperFormGroupToFormDataValidarCodigoInformado(value, this.parteForm.controls.codigoEncripto.value,
        this.parteForm.controls.emailConfirmacao.value, this.parteForm.controls.cpf.value))
        .pipe(
          takeWhile(() => true),
          tap(() => this.loading = false),
          catchError(err => {
            this.msg.error('Falha ao validar código de verificação.');
            return throwError(err);
          })
        )
        .subscribe(validacaoDuasEtapasAdvogado => {
          this.recuperarDadosAdvogado(this.parteForm.controls.cpf.value, value, this.parteForm.controls.codigoEncripto.value);
          this.parteForm.controls.validado.setValue(true);
          this.emailAdvo = validacaoDuasEtapasAdvogado.email;
          this.parteForm.controls.email.setValue(validacaoDuasEtapasAdvogado.email);
        });
    }

    this.codigoVerificacaoLoading = false;
    this.loading = false;
  }

  recuperarDadosAdvogado(cpf, codigoVerificacao, codigoEncripto) {
    this.advogadoService.recuperarDadosAdvogado(this.mapperFormGroupToFormDataRecuperarDadosAdvo(cpf, codigoVerificacao, codigoEncripto))
    .pipe(
      takeWhile(() => true),
      tap(() => this.loading = false),
      catchError(err => {
        this.msg.error('Falha ao recuperar os dados do Advogado.');
        return throwError(err);
      })
    )
    .subscribe(advogado => {
      this.rgAdvo = advogado.rg;
      this.dataNascimentoAdvo = advogado.dataNascimento;
      this.sexoAdvo = advogado.sexo == 'M' ? 'Masculino' : 'Feminino';
    });
  }

  resetCPFParteSuperiorForm(value) {
    this.parteForm.controls.cpf.setValue('');
  }


  resetParteInferiorForm() {
    this.parteForm.patchValue({
      nome: null,
      dataNascimento: null,
      sexo: null,
      rg: null,
      telefoneCelular: null,
      telefoneFixo: null,
      email: null,
      emailConfirmacao: null,
      arquivo: [''],
      arquivoPathDocumento: null,
      organizacao: null,
      aceiteTermoCompromisso: null,
      codigoOrganizacao: null
    });
  }

  onFileSelected(event, origemChamada) {
    if(event.target.files && event.target.files.length) {
      const file: File = event.target.files[0];

      if(origemChamada == "arquivoCPF"){
        this.parteForm.patchValue({
          "arquivoCPF": event.target.files[0],
          "arquivoCPFPath": event.target.files[0].name
        });
      }else if(origemChamada == "arquivoAutoRetrato"){
        this.parteForm.patchValue({
          "arquivoAutoRetrato": event.target.files[0],
          "arquivoAutoRetratoPath": event.target.files[0].name
        });
      }else{
        this.msg.error('Houve um erro ao anexar o arquivo.');
        return false;
      }

      event.srcElement.value = null;

      this.parteForm.get(origemChamada).markAsDirty();
      this.parteForm.get(origemChamada).updateValueAndValidity();
    }
  }

  excluirImagem(origemChamada) {
    if(origemChamada == "arquivoCPF"){
      this.parteForm.controls.arquivoCPFPath.setValue(null);
    }else if(origemChamada == "arquivoAutoRetrato"){
      this.parteForm.controls.arquivoAutoRetratoPath.setValue(null);
    }else{
      this.msg.error('Houve um erro ao excluir o arquivo.');
      return false;
    }
  }

  cadastrarAdvogado() {
    this.loading = true;

    const formData = this.mapperFormGroupToFormDataAdvo(this.parteForm);

    this.parteService.cadastrarAdvogado(formData)
      .pipe(
        takeWhile(() => true),
        tap(() => this.loading = false),
        catchError(err => {
          this.msg.error('Houve um erro ao criar a parte.');
          return throwError(err);
        })
      )
      .subscribe(res => {
        this.showConfirm("Peticionamento eletrônico", res.mensagem, () => this.redirecionarAutenticacaoSegurancaReturnUrlToPeticionamento())
      });
  }

  cadastrarUsuarioExterno() {
    this.loading = true;
    if(this.parteForm.invalid){
      this.msg.error('Ocorreu um erro ao criar a parte.');
      this.loading = false;
      return false;
    }else if(!this.parteForm.get('dataNascimento').value){
      this.msg.error('Informe o campo Data nascimento.');
      this.loading = false;
      return false;
    }else if(!this.parteForm.get('sexo').value){
      this.msg.error('Informe o o campo Sexo.');
      this.loading = false;
      return false;
    }else if(!this.parteForm.get('arquivoAutoRetratoPath').value){
      this.msg.error('Informe o arquivo com o auto retrato.');
      this.loading = false;
      return false;
    }

    const formData = this.mapperFormGroupToFormData(this.parteForm);

    this.parteService.cadastrarExterno(formData)
      .pipe(
        takeWhile(() => true),
        tap(() => this.loading = false),
        catchError(err => {
          this.msg.error('Houve um erro ao criar a parte.');
          return throwError(err);
        })
      )
      .subscribe(res => {
        this.showConfirm("Peticionamento eletrônico", res.mensagem, () => this.redirecionarAutenticacaoSegurancaReturnUrlToPeticionamento())
      });
  }

  redirecionarAutenticacaoSeguranca() {
    window.location.href = SEGURANCA_REDIRECT_URL_OLD;
  }

  redirecionarAutenticacaoSegurancaReturnUrlToPeticionamento() {
    window.location.href = SEGURANCA_URL_OLD + 'Login/?aplicacao=52&ReturnUrl=' + `https://${environment.production ? 'peticionamento' : 'peticionamento-hom'}.oab.org.br/`;
  }

  mapperFormGroupToFormData(form: FormGroup): FormData {
    const formData = new FormData();

    formData.append('souAdvogado', form.controls.advogado.value);
    formData.append('cpf', form.controls.cpf.value);
    formData.append('nome', form.controls.nome.value);
    formData.append('dddCel', form.controls.telefoneCelular.value.substr(0, 2));
    formData.append('telefoneCelular', form.controls.telefoneCelular.value.substr(2, 10));

    if (form.controls.telefoneFixo.value != null && form.controls.telefoneFixo.value != '') {
      formData.append('ddd', form.controls.telefoneFixo.value.substr(0, 2));
      formData.append('telefoneFixo', form.controls.telefoneFixo.value.substr(2, 8));
    }

    formData.append('email', form.controls.email.value);

    formData.append('arquivos[0]', this.parteForm.get('arquivoCPF').value);
    formData.append('arquivos[1]', this.parteForm.get('arquivoAutoRetrato').value);

    formData.append('organizacao', form.controls.codigoOrganizacao.value);

    if (form.controls.advogado.value == 0) {
      if(form.controls.rg.value!=null){
        formData.append('rg', form.controls.rg.value);
      }

      var datestr = (new Date(form.controls.dataNascimento.value)).toISOString();
      formData.append('dataNascimento', datestr);

      formData.append('sexo', form.controls.sexo.value);
    }
    return formData;
  }

  mapperFormGroupToFormDataAdvo(form: FormGroup): FormData {
    const formData = new FormData();

    formData.append('souAdvogado', form.controls.advogado.value);
    formData.append('cpf', form.controls.cpf.value);
    formData.append('nome', form.controls.nome.value);
    formData.append('dddCel', form.controls.telefoneCelular.value.substr(0, 2));
    formData.append('telefoneCelular', form.controls.telefoneCelular.value.substr(2, 10));

    if (form.controls.telefoneFixo.value != null && form.controls.telefoneFixo.value != '') {
      formData.append('ddd', form.controls.telefoneFixo.value.substr(0, 2));
      formData.append('telefoneFixo', form.controls.telefoneFixo.value.substr(2, 8));
    }

    formData.append('email', form.controls.email.value);
    formData.append('organizacao', form.controls.codigoOrganizacao.value);

    if (form.controls.advogado.value == 0) {
      var datestr = (new Date(form.controls.dataNascimento.value)).toISOString();

      formData.append('dataNascimento', datestr);
      formData.append('sexo', form.controls.sexo.value);
      formData.append('rg', form.controls.rg.value);
    }
    return formData;
  }

  mapperFormGroupToFormDataValidarCodigoInformado(codigoVerificacao: string, codigoEncripto: string, email: string, cpf: string): FormData {
    const formData = new FormData();
    formData.append('codigoVerificacao', codigoVerificacao);
    formData.append('codigoEncripto', codigoEncripto);
    formData.append('emailAnonimatizado', email);
    formData.append('cpf', cpf);
    return formData;
  }

  mapperFormGroupToFormDataRecuperarDadosAdvo(cpf: string, codigoVerificacao: string, codigoEncripto: string): FormData {
    const formData = new FormData();
    formData.append('codigoVerificacao', codigoVerificacao);
    formData.append('codigoEncripto', codigoEncripto);
    formData.append('cpf', cpf);
    return formData;
  }

  onChangeDataNascimento(result: Date): void {
    this.parteForm.patchValue({
      dataNascimento: result.getDate
    });
  }

  compareFn = (o1: any, o2: any) => (o1 && o2 ? o1.id === o2.id : o1 === o2);

  showConfirm(title: string, mensagem: string, func): void {
    this.confirmModal = this.modalService.success({
      nzTitle: title,
      nzContent: mensagem,
      nzOnOk: () => { this.redirecionarAutenticacaoSegurancaReturnUrlToPeticionamento() }
    });
  }

  showModal() {
    this.isModalVisible = true;
  }

  closeModal(): void {
    this.isModalVisible = false;
  }

  removeCtrlV(event, control: FormControl, str: string = null) {
    if (!str) { str = event.clipboardData.getData('text/plain'); }
    str = str.replace(/\s{2,}/g, ' ');
    str = str.replace(/\t/g, ' ');
    str = str.toString().trim().replace(/(\r\n|\n|\r)/g, '');
    event.preventDefault();
  }
}
